<template>
	<div>
		<div class="login-container">
			<star-background />
		</div>
		<div id="index" ref="appRef">
			<div class="bg">
				<dv-loading v-if="loading">Loading...</dv-loading>
				<div v-else class="host-body" @click="naVito()">
					智慧供热平台
				</div>
				<div class="body-center d-flex">
					<div class="leftDiv">
						<left-box :companyId="companyId"></left-box>
					</div>
					<div class="centerDiv">
						<center-top :topList="topList"></center-top>
						<center-bottom v-model="companyId"></center-bottom>
					</div>
					<div class="rightDiv">
						<success-rate style="margin-bottom: 20px;" :datalist="backWater" :backImage="backImage">
						</success-rate>
						<success-rate :datalist="forwardWater" :backImage="forwardwaterImage"></success-rate>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import StarBackground from '@/components/StarBackground' // Using Vue 3 <star-background /> component. Note the extra <> brackets.
	import drawMixin from "../utils/drawMixin";
	import successRate from './successRate';
	import leftBox from './components/leftbox.vue';
	import centerTop from './components/centerTop.vue';
	import centerBottom from './components/centerBottom.vue';
	import {
		homePage
	} from '@/assets/js/api';
	import backImage from '../assets/images/index/backwater.png'
	import forwardwaterImage from '../assets/images/index/forwardwater.png'
	import hot from '../assets/images/index/3.png'
	import hotArea from '../assets/images/index/4.png'
	export default {
		mixins: [drawMixin],
		data() {
			return {
				backImage: backImage,
				forwardwaterImage: forwardwaterImage,
				loading: true,
				companyId: '1',
				backWater: [],
				forwardWater: [],
				topList: []
			}
		},
		components: {
			leftBox,
			centerTop,
			centerBottom,
			StarBackground,
			successRate,
		},
		beforeCreate: function() {
			document.querySelector('body').setAttribute('style', 'background-attachment: fixed;overflow: hidden;')
		},
		created() {
			this.getList(this.companyId);
			// this.getforwardWater();
		},
		mounted() {
			// this.timeFn()
			this.cancelLoading()
			// this.getBaodingWeather()
		},
		beforeDestroy() {
			// clearInterval(this.timing)
		},
		watch: {
			companyId(newValue) {
				this.getList(newValue);
			}
		},
		methods: {
			naVito() {
				this.$router.push({
					name:"list",
					query: {id: 55}
				},()=>{},()=>{})
			},
			getList(companyId) {
				homePage({ companyId }).then(({ data }) => {
					this.topList = [{
							title: '供暖户数',
							icon: hot,
							number: Number(data.personCount),
							unit: '户'
						},
						{
							title: '总供暖面积',
							icon: hotArea,
							number: Number(data.area),
							unit: 'm³'
						},
					]
					const dataLists = data.machineList
					this.backWater = []
					this.forwardWater = []
					dataLists.forEach((item) => {
						this.backWater.push([item['stationName'], Number(item['ewHswd'])])
						this.forwardWater.push([item['stationName'], Number(item['ewGswd'])])
					})
					// console.log(res)
				})
				// homeData().then(({ data }) => {
				//   const dataLists = data.list
				//   dataLists.forEach((item) => {
				//     this.backWater.push([item['hrzmc'], item['gswd']])
				//   })
				//   // console.log(this.backWater)
				// })
			},
			getforwardWater() {
				// forwardWater().then(({ data }) => {
				//   const dataLists = data.list
				//   dataLists.forEach((item) => {
				//     this.forwardWater.push([item['hrzmc'], item['gswd']])
				//   })
				// })
			},
			// loginOut() {
			//   this.$auth.removeToken()
			//   this.$router.push({ name: 'login' })
			// },
			// timeFn() {
			//   this.timing = setInterval(() => {
			//     this.dateDay = formatTime(new Date(), 'HH: mm: ss')
			//     this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
			//     this.dateWeek = this.weekday[new Date().getDay()]
			//   }, 1000)
			// },
			//   getBaodingWeather() {
			//     this.$axios({
			//       methods: 'get',
			//       url: 'https://tianqiapi.com/free/day',
			//       headers: {},
			//       params: {
			//         appid: '43656176', // 账号ID
			//         appsecret: 'I42og6Lm', // 账号密钥
			//         cityid: '101090201', // 城市编号,不要带CN, 以下参数3选1，当前以西湖区举例
			//         city: null, // 城市名称,不要带市和区
			//         ip: null, // IP地址
			//         callback: 0, // jsonp方式
			//       },
			//     }).then((res) => {
			//       this.wea = res.data.wea;
			//       this.tem_night = res.data.tem_night;
			//       this.tem_day = res.data.tem_day;

			//     }).catch((error) => {
			//       console.log(error);
			//     });
			//   },
			cancelLoading() {
				setTimeout(() => {
					this.loading = false
				}, 500)
			}
		}
	};
</script>

<style scoped lang="scss">
	@import '../assets/scss/index.scss';

	.login-container {
		height: 100%;
		width: 100%;
		overflow: hidden;
		background-color: #050608;

	}

	.body-center {
		justify-content: space-between;
	}
</style>
