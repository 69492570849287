<template>
  <div class="contain">
    <div class="boxes">
      <box v-for="item in list" :key="item.id" :item="item"></box>
    </div>
  </div>
</template>

<script>
import {listData} from '@/assets/js/api'
import box from './box.vue'
export default {
  name: 'HeadAdditionLeftbox',
  components:{
    box
  },
  props: {
    companyId:{
      type:String,
      default:'1'
    }
  },  
  data() {
    return {
      list:[]
    };
  },
  created(){
    this.getData(this.companyId)
  },
  watch:{
    companyId(newValue){
      this.getData(newValue)
    }
  },
  mounted() {
  },

  methods: {
    getData(companyId='1'){
      const form = {
        companyId,
      }
      listData(form).then(({data})=>{
        this.list=data.list
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.contain{
  background: url('../../assets/images/index/left.png');
  width: 560px;
  height: 900px;
  padding: 72px 20px 48px;
  
  .boxes{
    height: 780px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    overflow: auto;
  }
}
</style>