<template>
	<div class="back">
		<div :class="item.class" v-for="item, index in list" :key="index" :style="{ top: item.top, left: item.left }"
			@click="changeType(item.value, index)">{{ item.title }}</div>
	</div>
</template>

<script>
	import { emit } from 'process';
	export default {
		name: 'HeadAdditionCenterBottom',

		data() {
			return {
				list: [{
						class: 'active',
						title: '一公司',
						top: '170px',
						left: '312px',
						value: '1'
					},
					{
						class: 'default',
						title: '二公司',
						top: '330px',
						left: '-15px',
						value: '2'
					},
					{
						class: 'default',
						title: '三公司',
						top: '300px',
						left: '134px',
						value: '3'
					},
				]
			};
		},
		mounted() {

		},
		model: {
			prop: 'value',
			event: 'changeType',
		},
		methods: {
			changeType(value, index) {
				this.$emit('changeType', value);
				this.list.map((item, i) => {
					if (i == index) {
						item.class = 'active'
					} else {
						item.class = 'default'
					}
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.back {
		background: url('../../assets/images/index/bottom.png');
		width: 800px;
		height: 723px;
		position: absolute;
		left: 50%;
		margin-left: -400px;
		bottom: 0;
		@mixin content {
			cursor: pointer;
			display: inline-block;
			position: relative;
			text-align: center;

		}
		.default {
			@include content();
			background: url('../../assets/images/index/default.png') no-repeat;
			font-size: 17px;
			font-family: PingFang SC-Bold, PingFang SC;
			width: 176px;
			height: 192px;
			font-weight: bold;
			color: #2E8BE6;
			line-height: 230px;
		}
		.active {
			@include content();
			background: url('../../assets/images/index/active.png') no-repeat;
			font-size: 20px;
			font-family: PingFang SC-Bold, PingFang SC;
			font-weight: bold;
			color: #00CBFF;
			width: 176px;
			height: 192px;
			line-height: 250px;
		}
	}
</style>
