<template>
  <div class="contanter">
    <div class="title">{{ item.stationName }}</div>
    <div class="number">
      <countTo :startVal='startVal' :endVal="+item.area" :duration='duration' :decimals="2" :separator="separator" ref="CountToPower" />
      <span style="font-size: 18px;color: #67CAFF;">m³</span></div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  name: 'HeadAdditionBox',
  props:['item'],
  components: { countTo },
  data() {
    return {
      startVal: 0.00,
      duration: 800, // 持续时间
      separator: '', // 分隔符
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.contanter{
  background: url('../../assets/images/index/box.png');
  width: 251.39px;
  height: 116.07px;
  padding: 20px;
  text-align: center;
  margin-bottom: 16px;
  .title{
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #67CAFF
  }
  .number{
    margin-top: 21px;
    font-size: 24px;
    font-family: DINPro-Medium, DINPro;
    font-weight: 500;
    color: #BBE7FF;
  }
}
</style>