<template>
  <div class="itembox">
    <div>
      <img :src="item.icon">
    </div>
    <div>
      <div class="title">{{ item.title }}</div>
      <div class="number"><countTo :startVal='startVal' :endVal="item.number" :duration='duration' :separator="separator" ref="CountToPower" /><span class="unit">{{ item.unit }}</span></div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  name: 'HeadAdditionItemBox',
  components: { countTo },
  props:['item','companyId'],
  data() {
    return {
      startVal: 0.00,
      duration: 800, // 持续时间
      separator: '', // 分隔符
    };
  },
  watch:{
    companyId(){
      console.log()
      this.$refs.CountToPower.start();
    }
  },
  mounted() {
    // const countUp = new CountUp('targetId', 5234);
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.itembox{
  display: flex;
  background: url('../../assets/images/index/itembox.png');
  width: 280px;
  height: 110px;
  justify-content: space-around;
  padding: 22px 40px;
  margin-bottom: 21px;
  .title{
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #E5F6FF;
  }
  .number{
    margin-top:22px;
    font-size: 26px;
    font-family: DINPro-Bold, DINPro;
    font-weight: bold;
    color: #E5F6FF;
    display: flex;
    .unit{
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #E5F6FF;
      margin-left: 8px;
    }
  }
}
</style>