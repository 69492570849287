<template>
  <div id="successRate" :style="{backgroundImage:'url('+backImage+')'}">
    <div class="d-flex header">
      <div style="width: 56px;">序号</div>
      <div style="width: 227px;text-align: left;">换热站名称</div>
      <div style="width: 81px;text-align: left;">统计值(°C)</div>
    </div>
    <dv-scroll-board class="dv-scr-board" :config="config" />
  </div>
</template>

<script>

export default {
  name: "successRate",
  data(){
    return{
      config: {
        // header: ['换热站', '统计值（%）'],
        data: [],
        rowNum: 4, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50],
        align: ['center'],
      }
    }
  },
  props:{
    datalist:Array,
    backImage:{
      type:String,
    }
  },
  created() {
    this.getLists(this.datalist)
  },
  watch:{
    datalist:{
      handler(newValue){
        console.log(newValue)
        this.getLists(newValue)
      },
      immediate:true
    }
  },
  methods:{
    getLists(data) {
      this.config = {
          // header: ['换热站', '统计值（℃）'],
          data,
          rowNum: 5, //表格行数
          // headerHeight: 35,
          headerBGC: '#0f1325', //表头
          oddRowBGC: '#0f1325', //奇数行
          evenRowBGC: '#171c33', //偶数行
          index: true,
          columnWidth: [50],
          align: ['center']}
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 560px;
$box-height: 440px;
#successRate {
  padding: 70px 30px 30px;
  height: $box-height;
  width: $box-width;
  background: url('../assets/images/index/forwardwater.png');
  .header{
    font-size: 16px;
    width: 500px;
    line-height: 40px;
    color: #009AF4;
    background: linear-gradient(180deg, rgba(0,156,246,0.3) 0%, rgba(0,67,106,0.2) 100%);
    div{
      text-align: center;
    }
  }
  .dv-scr-board{
    height: 300px;
  }
}
</style>