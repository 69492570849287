<template>
  <div class="container">
    <itemBox v-for="item,index in topList" :key="index" :item="item"></itemBox>
  </div>
</template>

<script>
import itemBox from './itemBox.vue';
import hourse from '../../assets/images/index/1.png'
import buildings from '../../assets/images/index/2.png'
import hot from '../../assets/images/index/3.png'
import hotArea from '../../assets/images/index/4.png'
export default {
  name: 'HeadAdditionCenter',
  components:{
    itemBox
  },
  props:{
    topList:{
      type:Array,
      default:()=>[
        {
          title:'供暖户数',
          icon:hot,
          number:876,
          unit:'户'
        },
        {
          title:'总供暖面积',
          icon:hotArea,
          number:9864,
          unit:'m³'
        },
      ]
    }
  },
};
</script>

<style lang="scss" scoped>
.container{
  background: url('../../assets/images/index/center.png');
  width: 640px;
  height: 348px;
  display: flex;
  flex-wrap: wrap;
  padding: 72px 30px 35px;
  justify-content: space-between;
}
</style>